export const api_url = 'https://thirdspacebackend.third-space.in/api/en/';
export const image_url = 'https://thirdspacebackend.third-space.in/storage/';
export const homeVideoBaseURL = 'https://thirdspacebackend.third-space.in/vedio/';
export const base_url='https://www.third-space.in/';

// export const api_url = "http://localhost:8000/api/en/";
// export const image_url = "http://localhost:8000/storage/";
// export const homeVideoBaseURL = "http://localhost:8000/vedio/";
// export const base_url = "http://localhost:8000/";


// export const api_url = "http://thirdspacebackend.brandtalks.in/api/en/";
// export const image_url = "http://thirdspacebackend.brandtalks.in/storage/";
// export const homeVideoBaseURL = "http://thirdspacebackend.brandtalks.in/vedio/";
// export const base_url = "https://www.third-space.in/";
