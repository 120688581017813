import React, { Component, useEffect, useState } from "react";
import facebookIcon from "./../assets/facebook icon.svg";
import linkedinIcon from "./../assets/linkdin icon.svg";
import instaIcon from "./../assets/ig icon.svg";
import facebookMobileIcon from "./../assets/facebook icon.svg";
import linkedinMobileIcon from "./../assets/linkdin icon.svg";
import instaMobileIcon from "./../assets/ig icon.svg";
import secureMobileImg from "./../assets/secure_mobile.png";
import dharoharMobileImg from "./../assets/dharohar_mobile.png";
import "./../css/footer.css";
import { Link } from "react-router-dom";
import { api_url, image_url } from "../config";
import axios from "axios";
import { data } from "jquery";

function Footer() {
  const [footer, setFooter] = useState({ text: [], icon: [] });

  useEffect(() => {
    axios
      .get(api_url + `footerlink`)
      .then((response) => {
        const obj = { text: [], icon: [] };
        response.data.footerlink.forEach((item) => {
          obj[item.titleType].push(item);
        });
        setFooter(obj);
      })
      .catch((err) => console.log(["APIGet error:", err]));
  }, [api_url]);

  return (
    <div>
      <section className="footerSection">
        <div className="footer_background">
          <div
            className="absolute_div"
            onClick={() =>
              window.scroll({ top: 0, left: 0, behavior: "smooth" })
            }
          ></div>
          <div className="row">
            <div className="col-10 d-flex flex-column">
              {footer.text.map((item, key) => {
                return (
                  <Link
                    to={item.titleLink}
                    key={item.id}
                    style={{ fontSize: "18px", padding: " 0 0 19px 0px" }}
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
            <div className="col-2 d-flex flex-column align-items-end">
              {footer.icon.map((item, key) => {
                return (
                  <div key={item.id}>
                    {item.title === "facebook" ? (
                      <a href={item.titleLink} target="_blank">
                        <img
                          src={image_url + `/iconImage/` + item.iconImage}
                          alt=""
                          style={{ fontSize: "18px", margin: "10px" }}
                        />
                      </a>
                    ) : null}
                    {item.title === "instagram" ? (
                      <a href={item.titleLink} target="_blank">
                        <img
                          src={image_url + `/iconImage/` + item.iconImage}
                          alt=""
                          style={{
                            fontSize: "18px",
                            margin: "10px",
                            height: "24px",
                          }}
                        />
                      </a>
                    ) : null}
                    {item.title === "linkedin" ? (
                      <a href={item.titleLink} target="_blank">
                        <img
                          src={image_url + `/iconImage/` + item.iconImage}
                          alt=""
                          style={{
                            fontSize: "18px",
                            margin: "10px",
                            height: "24px",
                          }}
                        />
                      </a>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div className="d-flex align-items-center justify-content-between main_row">
                
                    
                  
                 
                    <div
                      className="d-flex align-items-center justify-content-end"
                      style={{ width: "140px" }}
                    >
                      <div>
                        <a
                          href="https://www.facebook.com/thirdspace.udr/"
                          target="_blank"
                        >
                          <img src={facebookIcon} alt="" />
                        </a>
                      </div>
                    </div>
                 
              </div> */}
          <div className="d-flex align-items-center justify-content-between copyright-row">
            <div>
              Third Space <i className="fa-regular fa-copyright"></i> 2024. All
              rights reserved
            </div>
            <div>
              Supported by{" "}
              <span style={{ fontFamily: "kohinoorregular" }}>
                Secure Meters
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="footer_mobile">
        <div className="d-flex align-items-center justify-content-between">
          <div className="socials d-flex justify-content-between">
            {footer.icon.map((item, key) => {
              return (
                <>
                  <div className="d-flex justify-content-between">
                    {item.title === "facebook" ? (
                      <a href={item.titleLink} target="_blank">
                        <img
                          src={image_url + `/iconImage/` + item.iconImage}
                          alt=""
                          className="footer-socials-2"
                        />
                      </a>
                    ) : null}
                    {item.title === "instagram" ? (
                      <a href={item.titleLink} target="_blank">
                        <img
                          src={image_url + `/iconImage/` + item.iconImage}
                          alt=""
                          className="footer-socials-2"
                        />
                      </a>
                    ) : null}
                    {item.title === "linkedin" ? (
                      <a href={item.titleLink} target="_blank">
                        <img
                          src={image_url + `/iconImage/` + item.iconImage}
                          alt=""
                          className="footer-socials-2"
                        />
                      </a>
                    ) : null}
                  </div>
                </>
              );
            })}
          </div>
          {footer.icon.map((item, key) => {
            return (
              <>
                {item.title === "dharohar" ? (
                  <a href={item.titleLink} target="_blank">
                    <img
                      src={image_url + `/iconImage/` + item.iconImage}
                      alt=""
                      className="footerLogo"
                    />
                  </a>
                ) : null}

                {item.title === "secure" ? (
                  <a href={item.titleLink} target="_blank">
                    <img
                      src={image_url + `/iconImage/` + item.iconImage}
                      alt=""
                      className="footerLogo"
                    />
                  </a>
                ) : null}
              </>
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default Footer;
