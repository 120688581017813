import React, { useEffect, useState } from "react";
import Breadcrumb from "./breadcrumb";
import { image_url } from "../config";
import { Link } from 'react-router-dom';
import './../css/space.css'

const Spaces = (props) => {


    let imgURL = image_url

    const [pageBreadcrumb, setBreadcrumb] = useState();
    const [bannerImage, setBannerImage] = useState();
    const [secondSectionData, setSecondSection] = useState();

    useEffect(() => {
        setBreadcrumb(props?.data[0][0].page_name);
        props?.data?.map((res) => {
            if (res[0]?.section_name == 'banner') {
                setBannerImage(imgURL + 'banner_image/' + res[0]?.image);
            } else if (res[0]?.section_name == 'second_section') {
                setSecondSection(res);
            }
        });
    })

    const breadcrumbObject = {
        img: bannerImage,
        breadcumbs: pageBreadcrumb
    }

    // const sectionThirdData = [
    //     {
    //         "id": 4,
    //         "page_id": 1,
    //         "lang_type": "en",
    //         "section_name": "third_section",
    //         "section_id": 4,
    //         "heading": "If you like the library, check out...",
    //         "sub_heading": "Cinema",
    //         "content_heading": null,
    //         "from_time": null,
    //         "to_time": null,
    //         "text_content": null,
    //         "image": "c5a0685c-1ee4-4c18-9cff-2ce178e2431a1685709007.jpg",
    //         "image_alt": "Cinema",
    //         "image_title": "Cinema",
    //         "image_caption": "Cinema",
    //         "sort_image": null,
    //         "sort_image_alt": null,
    //         "sort_image_title": null,
    //         "sort_image_caption": null,
    //         "url": "https://thirdspace.brandtalks.in/teesra-cinema",
    //         "category_id": null,
    //         "sub_category_id": null,
    //         "meta_title": null,
    //         "meta_description": null,
    //         "keywords": null,
    //         "tags": null,
    //         "og_title": null,
    //         "og_description": null,
    //         "og_image": null,
    //         "og_alt": null,
    //         "json_data": null,
    //         "status": 0,
    //         "flag": 0,
    //         "created_at": "2023-04-24T11:23:43.000000Z",
    //         "updated_at": "2023-06-02T12:30:07.000000Z"
    //     },
    //     {
    //         "id": 5,
    //         "page_id": 1,
    //         "lang_type": "en",
    //         "section_name": "third_section",
    //         "section_id": 4,
    //         "heading": "If you like the library, check out...",
    //         "sub_heading": "Shop",
    //         "content_heading": null,
    //         "from_time": null,
    //         "to_time": null,
    //         "text_content": null,
    //         "image": "cbd439de-609f-4d0d-989f-487aa595a8c41684237579.jpg",
    //         "image_alt": null,
    //         "image_title": null,
    //         "image_caption": null,
    //         "sort_image": null,
    //         "sort_image_alt": null,
    //         "sort_image_title": null,
    //         "sort_image_caption": null,
    //         "url": null,
    //         "category_id": null,
    //         "sub_category_id": null,
    //         "meta_title": null,
    //         "meta_description": null,
    //         "keywords": null,
    //         "tags": null,
    //         "og_title": null,
    //         "og_description": null,
    //         "og_image": null,
    //         "og_alt": null,
    //         "json_data": null,
    //         "status": 0,
    //         "flag": 0,
    //         "created_at": "2023-04-24T11:23:43.000000Z",
    //         "updated_at": "2023-05-16T11:46:19.000000Z"
    //     },
    //     {
    //         "id": 6,
    //         "page_id": 1,
    //         "lang_type": "en",
    //         "section_name": "third_section",
    //         "section_id": 4,
    //         "heading": "If you like the library, check out...",
    //         "sub_heading": "Science lab",
    //         "content_heading": null,
    //         "from_time": null,
    //         "to_time": null,
    //         "text_content": null,
    //         "image": "f7078921-09a6-4961-bf24-98bc39fabe3f1684237579.jpg",
    //         "image_alt": null,
    //         "image_title": null,
    //         "image_caption": null,
    //         "sort_image": null,
    //         "sort_image_alt": null,
    //         "sort_image_title": null,
    //         "sort_image_caption": null,
    //         "url": null,
    //         "category_id": null,
    //         "sub_category_id": null,
    //         "meta_title": null,
    //         "meta_description": null,
    //         "keywords": null,
    //         "tags": null,
    //         "og_title": null,
    //         "og_description": null,
    //         "og_image": null,
    //         "og_alt": null,
    //         "json_data": null,
    //         "status": 0,
    //         "flag": 0,
    //         "created_at": "2023-04-24T11:23:43.000000Z",
    //         "updated_at": "2023-05-16T11:46:19.000000Z"
    //     },
    //     {
    //         "id": 4,
    //         "page_id": 1,
    //         "lang_type": "en",
    //         "section_name": "third_section",
    //         "section_id": 4,
    //         "heading": "If you like the library, check out...",
    //         "sub_heading": "Cinema",
    //         "content_heading": null,
    //         "from_time": null,
    //         "to_time": null,
    //         "text_content": null,
    //         "image": "c5a0685c-1ee4-4c18-9cff-2ce178e2431a1685709007.jpg",
    //         "image_alt": "Cinema",
    //         "image_title": "Cinema",
    //         "image_caption": "Cinema",
    //         "sort_image": null,
    //         "sort_image_alt": null,
    //         "sort_image_title": null,
    //         "sort_image_caption": null,
    //         "url": "https://thirdspace.brandtalks.in/teesra-cinema",
    //         "category_id": null,
    //         "sub_category_id": null,
    //         "meta_title": null,
    //         "meta_description": null,
    //         "keywords": null,
    //         "tags": null,
    //         "og_title": null,
    //         "og_description": null,
    //         "og_image": null,
    //         "og_alt": null,
    //         "json_data": null,
    //         "status": 0,
    //         "flag": 0,
    //         "created_at": "2023-04-24T11:23:43.000000Z",
    //         "updated_at": "2023-06-02T12:30:07.000000Z"
    //     },
    //     {
    //         "id": 5,
    //         "page_id": 1,
    //         "lang_type": "en",
    //         "section_name": "third_section",
    //         "section_id": 4,
    //         "heading": "If you like the library, check out...",
    //         "sub_heading": "Shop",
    //         "content_heading": null,
    //         "from_time": null,
    //         "to_time": null,
    //         "text_content": null,
    //         "image": "cbd439de-609f-4d0d-989f-487aa595a8c41684237579.jpg",
    //         "image_alt": null,
    //         "image_title": null,
    //         "image_caption": null,
    //         "sort_image": null,
    //         "sort_image_alt": null,
    //         "sort_image_title": null,
    //         "sort_image_caption": null,
    //         "url": null,
    //         "category_id": null,
    //         "sub_category_id": null,
    //         "meta_title": null,
    //         "meta_description": null,
    //         "keywords": null,
    //         "tags": null,
    //         "og_title": null,
    //         "og_description": null,
    //         "og_image": null,
    //         "og_alt": null,
    //         "json_data": null,
    //         "status": 0,
    //         "flag": 0,
    //         "created_at": "2023-04-24T11:23:43.000000Z",
    //         "updated_at": "2023-05-16T11:46:19.000000Z"
    //     },
    //     {
    //         "id": 6,
    //         "page_id": 1,
    //         "lang_type": "en",
    //         "section_name": "third_section",
    //         "section_id": 4,
    //         "heading": "If you like the library, check out...",
    //         "sub_heading": "Science lab",
    //         "content_heading": null,
    //         "from_time": null,
    //         "to_time": null,
    //         "text_content": null,
    //         "image": "f7078921-09a6-4961-bf24-98bc39fabe3f1684237579.jpg",
    //         "image_alt": null,
    //         "image_title": null,
    //         "image_caption": null,
    //         "sort_image": null,
    //         "sort_image_alt": null,
    //         "sort_image_title": null,
    //         "sort_image_caption": null,
    //         "url": null,
    //         "category_id": null,
    //         "sub_category_id": null,
    //         "meta_title": null,
    //         "meta_description": null,
    //         "keywords": null,
    //         "tags": null,
    //         "og_title": null,
    //         "og_description": null,
    //         "og_image": null,
    //         "og_alt": null,
    //         "json_data": null,
    //         "status": 0,
    //         "flag": 0,
    //         "created_at": "2023-04-24T11:23:43.000000Z",
    //         "updated_at": "2023-05-16T11:46:19.000000Z"
    //     }
    // ]

    return (
        <>
            <Breadcrumb data={breadcrumbObject} />
            <section className="space_listing_section">
                {/* <h2 className="main_heading">{sectionCardHeading}</h2> */}
                <div className="row m-0 nav_link_cards">
                    {secondSectionData?.map((res, index) =>
                        <div className="project_card">
                            {/* <a style={{ textDecoration: "none", cursor: "pointer" }} href={res?.url}> */}
                            <Link to={'/'+res?.url}>
                                <div className="card_box">
                                    <div className="card_image">
                                        <img src={imgURL + 'section_third_list_image/' + res?.image} />
                                    </div>
                                    <div className="card_content">
                                        <h2 className="card_heading m-0 heading_family">{res?.heading}</h2>
                                        {index % 3 == 0 ?
                                            <div className="design_section" style={{ background: "#ffca05" }}></div>
                                            : ''}
                                        {index % 3 == 1 ?
                                            <div className="design_section" style={{ background: "#f26f21" }}></div>
                                            : ''}
                                        {index % 3 == 2 ?
                                            <div className="design_section" style={{ background: "#e21f26" }}></div>
                                            : ''}
                                    </div>
                                </div>
                            </Link>
                            {/* </a> */}
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}

export default Spaces;