import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { BrowserRouter, Link } from 'react-router-dom';
import RouterFunction from './router/routes.js';
import icon1 from './assets/Buy ticket-white-02.png'
import icon2 from './assets/Calendar-01-white-02.png'
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

function App() {
  return (
    <>
    <div className='classForMargin'></div>
        <RouterFunction />

      <section className="buy_now_section">
        <div className="row m-0">
          <div className="col-6"><a href='https://booking.third-space.in/'><img src={icon1} style={{height:"20px"}} alt="" />Buy ticket</a></div>
          <div className="col-6"><img style={{height:"25px"}} src={icon2} alt="" />What's on</div>
        </div>
      </section>
    </>
  );
}

export default App;
