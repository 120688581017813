import React, { Component, useEffect, useState } from 'react';
import mapImage from './../assets/map.png'
import img1 from './../assets/Group 1.png'
import img2 from './../assets/Hang out.png'
import img3 from './../assets/Cinema.png'
import img4 from './../assets/Science.png'
import img5 from './../assets/Shop.png'
import gif from './../assets/Third-Space-Site.gif'
import { api_url, homeVideoBaseURL, image_url } from '../config';
import { Link, useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import HomePageGif from './HomePageGif';


const Home = (props) => {
    
    let imgURL = image_url;

    // const headerTemplate = (data) => {
    //     return (
    //         <React.Fragment>
    //             <img alt={data.representative.name} src={`https://primefaces.org/cdn/primereact/images/avatar/${data.representative.image}`} width="32" style={{ verticalAlign: 'middle' }} className="ml-2" />
    //             <span className="vertical-align-middle ml-2 font-bold line-height-3">{data.representative.name}</span>
    //         </React.Fragment>
    //     );
    // };

    // const countryBodyTemplate = (rowData) => {
    //     return (
    //         <div className="flex align-items-center gap-2">
    //             <img alt={rowData.country.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} style={{ width: '24px' }} />
    //             <span>{rowData.country.name}</span>
    //         </div>
    //     );
    // };

    // const statusBodyTemplate = (rowData) => {
    //     return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
    // };

    const getSeverity = (status) => {
        switch (status) {
            case 'unqualified':
                return 'danger';

            case 'qualified':
                return 'success';

            case 'new':
                return 'info';

            case 'negotiation':
                return 'warning';

            case 'renewal':
                return null;
        }
    };

    const [bannerImage, setBannerImage] = useState();
    const [firstSectionData, setFirstSectionData] = useState();
    const [secondSectionData, setSecondData] = useState();
    const [thirdSectionData, setThirdData] = useState();

    const getData =() => {
        props?.data?.map((res) => {
            if (res[0]?.section_name == 'banner') {
                setBannerImage(imgURL + 'banner_image/' + res[0]?.image);
            } else if (res[0]?.section_name == 'first_section') {
                setFirstSectionData(res[0]);
            } else if (res[0]?.section_name == 'second_section') {
                setSecondData(res[0]);
                setThirdData(JSON.parse(res[0]?.json_data))
            }
        });
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(()=>{
        getData()
    },[props?.data])


    return (
        <>
            <section className="static_section">
                <div className="map_col">
                    {/* <img src={bannerImage} /> */}
                    {/* <video preload="auto" muted autoplay="true" loop="true">
                        <source src={homeVideoBaseURL + "My%20Video.mp4"} type="video/mp4" />
                    </video> */}
                    <div className='homePageMap d-sm-none d-flex'>
                    {/* <HomePageGif /> */}
                    <img src={`${bannerImage}`} style={{width:"100%"}}/>
                
                    </div>
                    <div className='homePageMap d-none d-sm-flex'>
                    {/* <HomePageGif /> */}
                    <img src={`${bannerImage}`} style={{width:"75%"}}/>
                
                    </div>
                    
                    {/* <img src="https://thirdspacebackend.third-space.in/images/TS_map_final%201.gif"  className='img_for_mobile' /> */}
                </div>
            </section>
            <section className="main_content_details homeBottomText">
                <div className="details_section">
                    <div className="heading_section">
                        {/* <h1 className="main_heading">
                            {firstSectionData?.heading}
                        </h1> */}
                    </div>
                    <div className="both_section">
                        <div className="content_section">
                            <p dangerouslySetInnerHTML={{ __html: firstSectionData?.text_content}} className="light-color">
                               
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="details_section">
                    <div className="heading_section">
                        <h1 className="main_heading">
                            {secondSectionData?.heading}
                        </h1>
                    </div>
                    <div className="both_section">
                        <div className="content_section">
                            <p className="light-color">{secondSectionData?.text_content}</p>
                        </div>
                    </div>
                </div> */}
            </section>
            <section className="categorySection">
                <div className="row m-0">
                    {thirdSectionData?.map((res) => 
                        <div className="col-3">
                            
                                <div>
                                    <Link to={res?.url}>
                                    <img src={image_url + 'section_second_image/' + res?.image} alt="" />
                                    <p className="category_name">{res?.title}</p>
                                    </Link>
                                </div> 
                                
                            
                        </div>
                    )}
                </div>
            </section>

            {/* <DataTable value={customers} rowGroupMode="subheader" groupRowsBy="representative.name"
                sortMode="single" sortField="representative.name" sortOrder={1}
                expandableRowGroups expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                rowGroupHeaderTemplate={headerTemplate} tableStyle={{ minWidth: '50rem' }}>
                <Column field="name" header="Name" style={{ width: '20%' }}></Column>
                <Column field="country" header="Country" body={countryBodyTemplate} style={{ width: '20%' }}></Column>
                <Column field="company" header="Company" style={{ width: '20%' }}></Column>
                <Column field="status" header="Status" body={statusBodyTemplate} style={{ width: '20%' }}></Column>
                <Column field="date" header="Date" style={{ width: '20%' }}></Column>
            </DataTable> */}

        </>
    );
}

export default Home;