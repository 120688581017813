import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { api_url, image_url } from '../config.js';
import Breadcrumb from './breadcrumb.js';
import './../css/opening.css'
import Carousels from "./third-space-dynamic-map/Carousels.js";


function Opening(props) {
    let imgURL = image_url;

    const [pageBreadcrumb, setBreadcrumb] = useState();
    const [bannerImage, setBannerImage] = useState();
    const [firstSectionData, setFirstSectionData] = useState();



    useEffect(() => {
        const getData = async () => {
            setBreadcrumb(props?.data[0][0].page_name);
            props?.data?.forEach((res) => {

                if (res[0]?.section_name == 'banner') {
                    setBannerImage(imgURL + 'banner_image/' + res[0]?.image);
                } else if (res[0]?.section_name == 'section_second') {
                    setFirstSectionData(res);
                }

            });
        };
        getData();
    }, []);

    const breadcrumbObject = {
        breadcumbs: pageBreadcrumb
    }
    return (

        <>
            <Breadcrumb data={breadcrumbObject} />
         <Carousels/>
            <section className="space_listing_section">
                {/* <h2 className="main_heading">{sectionCardHeading}</h2> */}
                <div className="row m-0 nav_link_cards">
                    {firstSectionData?.map((res, index) =>
                        <div className="project_card">
                            {/* <a style={{ textDecoration: "none", cursor: "pointer" }} href={res?.url}> */}
                            {/* <Link to={'/'}> */}
                                <div className="opening_template_card_box">

                                    <div className="opening_template_card_content">
                                        <h2 className="card_heading m-0 heading_family">{res?.heading}</h2>
                                        <p className="light-color" dangerouslySetInnerHTML={{ __html: res?.text_content }}></p>

                                        {index % 3 == 0 ?
                                        <div className="design_section" style={{ background: "#ffca05" }}></div>
                                        : ''}
                                        {index % 3 == 1 ?
                                        <div className="design_section" style={{ background: "#f26f21" }}></div>
                                        : ''}
                                        {index % 3 == 2 ?
                                        <div className="design_section" style={{ background: "#e21f26" }}></div>
                                        : ''}
                                    </div>
                                </div>
                            {/* </Link> */}
                            {/* </a> */}
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}
export default Opening;