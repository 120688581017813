import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { api_url, image_url } from './../config.js';
import Breadcrumb from './breadcrumb';
import cardimage from '../assets/card_img.png'

function Accessibility(props) {
    let imgURL = image_url;


    const [bannerImage, setBannerImage] = useState();
    const [firstSectionData, setFirstSectionData] = useState();
    const [secondSectionData, setSecondData] = useState();
    const [sectionThirdData, setThirdData] = useState();
    const [sectionFourthData, setFourthData] = useState();
    const [sectionCardHeading, setCardHeading] = useState();
    const [pageBreadcrumb, setBreadcrumb] = useState();

    useEffect(() => {
        const getData = async () => {
            setBreadcrumb(props?.data[0][0].page_name);
            props?.data?.forEach((res) => {

                if (res[0]?.section_name == 'banner') {
                    setBannerImage(imgURL + 'banner_image/' + res[0]?.image);
                } else if (res[0]?.section_name == 'section_two_heading') {
                    setFirstSectionData(res[0]);
                } else if (res[0]?.section_name == 'third_section') {
                    setSecondData(res);
                } else if (res[0]?.section_name == 'section_fourth_heading') {
                    setThirdData(res[0]);
                }

            });
        };
        getData();
    }, []);
    const breadcrumbObject = {
        img: bannerImage,
        breadcumbs: pageBreadcrumb
    }
    return (
        <>
            <Breadcrumb data={breadcrumbObject} />
            <section className="library_section_desktop main_content_details accessibility_section">
                <div className="row m-0 library_row">
                    <div className="col-md-12 col-12 lib-content-col">
                        <div className="details_section">
                            <div className="heading_section">
                                <h1 className="main_heading_desktop">
                                    {/* Still worried about something? */}
                                    {firstSectionData?.heading}
                                </h1>
                                <h1 className="main_heading_mobile">
                                    {firstSectionData?.heading}
                                </h1>
                                {/* {firstSectionData?.sub_heading ? */}
                                <h2 className='main_library_subHeading'>

                                    {/* {firstSectionData?.sub_heading} */}
                                </h2>
                                {/* : ''} */}
                                {/* {(firstSectionData?.from_time && firstSectionData?.to_time) ? */}
                                {/* <p className='firstSection_timing'> */}

                                {/* {firstSectionData?.from_time} - {firstSectionData?.to_time} */}
                                {/* </p> */}
                                {/* : ''} */}
                            </div>
                            <div className="both_section">
                                <div className="content_section accessibility_content">
                                    <p dangerouslySetInnerHTML={{ __html: firstSectionData?.text_content }}>

                                        {/* <p className="light-color">We are here to listen!</p><br/>
                                    <p className="light-color">We want Third Space to become an open and accessible space for all.</p><br/>
                                    <p className="light-color"> As we grow, we want to learn and understand our visitors’ needs. To ensure that visitors can access our space with ease and independence, we have: */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="visit_listing_section">
                {/* <h2 className="main_heading">{sectionCardHeading}</h2> */}
                <div className="row m-0 nav_link_cards">
                    {secondSectionData?.map((res, index) =>
                        <div className="project_card">
                            {/* <a style={{ textDecoration: "none", cursor: "pointer" }} href={res?.url}> */}
                            {/* <Link to={res?.url ?? ''}> */}
                                <div className="card_box">
                                    <div className="card_image">
                                        <img src={imgURL + 'section_third_image/' + res?.image} alt="" />
                                    </div>
                                    <div className="card_content">
                                        <h2 className="visit_card_heading m-0 heading_family">{res?.heading}</h2>

                                        {index % 3 == 0 ?
                                            <div className="design_section" style={{ background: "#ffca05" }}></div>
                                            : ''}
                                        {index % 3 == 1 ?
                                            <div className="design_section" style={{ background: "#f26f21" }}></div>
                                            : ''}
                                        {index % 3 == 2 ?
                                            <div className="design_section" style={{ background: "#e21f26" }}></div>
                                            : ''}

                                    </div>
                                </div>
                            {/* </Link> */}
                            {/* </a> */}
                        </div>
                    )}

                </div>
            </section>
            {/* <section className="accessibility_footer_section">
                <div className="footer_section_content">
                    <p dangerouslySetInnerHTML={{ __html: sectionThirdData?.text_content }}></p>
                </div>
            </section> */}
        </>
    )
}

export default Accessibility;